<header fxLayout="row" fxLayoutAlign="space-between center" class="navbar">
  <a [routerLink]="['/']">
    <img
      id="logo"
      src="/assets/img/logo.svg"
      alt="Gill Photography Logo"
      data-cursor="cursor-link"
    />
  </a>

  <div
    class="main-nav-wrapper"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div
      fxHide.gt-sm
      (click)="toggleNav()"
      class="hamburger"
      id="hamburger"
      [ngClass]="{ 'is-active': isActive === true }"
    >
      <span class="line"></span>
      <span class="line"></span>
      <span class="line"></span>
    </div>

    <nav class="main-nav" [ngClass]="{ slide: isActive === true }">
      <ul>
        <li [ngClass]="{ fade: isActive === true }">
          <a (click)="onClick('/')" class="nav-links" data-cursor="cursor-link"
            >Home</a
          >
        </li>
        <li [ngClass]="{ fade: isActive === true }">
          <a
            (click)="onClick('/services')"
            class="nav-links"
            data-cursor="cursor-link"
            >Services</a
          >
        </li>
        <li [ngClass]="{ fade: isActive === true }">
          <a
            (click)="onClick('/minis')"
            class="nav-links"
            data-cursor="cursor-link"
            >Minis</a
          >
        </li>
        <li [ngClass]="{ fade: isActive === true }">
          <a
            (click)="onClick('/about')"
            class="nav-links"
            data-cursor="cursor-link"
            >About</a
          >
        </li>
        <li [ngClass]="{ fade: isActive === true }">
          <a
            (click)="onClick('/contact')"
            class="nav-links"
            data-cursor="cursor-link"
            >Contact</a
          >
        </li>
        <li [ngClass]="{ fade: isActive === true }">
          <a
            (click)="onClick('/blog')"
            class="nav-links"
            data-cursor="cursor-link"
            >Blog</a
          >
        </li>
      </ul>
    </nav>

    <button class="share" (click)="toggleSocialMenu()">
      <div
        class="share-close"
        [ngStyle]="{
          display: socialsOpen === true ? 'block' : 'none'
        }"
      >
        &times;
      </div>
      <svg
        data-cursor="cursor-btn"
        [ngStyle]="{
          display: socialsOpen !== true ? 'block' : 'none'
        }"
        width="24"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
        fill-rule="evenodd"
        clip-rule="evenodd"
      >
        <path
          d="M16.272 5.451c-.176-.45-.272-.939-.272-1.451 0-2.208 1.792-4 4-4s4 1.792 4 4-1.792 4-4 4c-1.339 0-2.525-.659-3.251-1.67l-7.131 3.751c.246.591.382 1.239.382 1.919 0 .681-.136 1.33-.384 1.922l7.131 3.751c.726-1.013 1.913-1.673 3.253-1.673 2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4c0-.51.096-.999.27-1.447l-7.129-3.751c-.9 1.326-2.419 2.198-4.141 2.198-2.76 0-5-2.24-5-5s2.24-5 5-5c1.723 0 3.243.873 4.143 2.201l7.129-3.75zm3.728 11.549c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3zm-15-9c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm15-7c1.656 0 3 1.344 3 3s-1.344 3-3 3-3-1.344-3-3 1.344-3 3-3z"
        />
      </svg>
      <span>Share</span>
    </button>

    <button
      class="dots"
      (click)="toggleSidebar()"
      [ngClass]="{ on: dotsActive === true }"
      data-cursor="cursor-btn"
    >
      <span></span>
    </button>
  </div>

  <div class="social-menu" [ngClass]="{ slide: socialsOpen === true }">
    <ul>
      <li>
        <a
          [href]="
            'http://www.facebook.com/share.php?u=https://gill-photography.com.au' +
            currentRoute
          "
          target="_blank"
          class="social-menu-item"
          >01. Facebook</a
        >
      </li>
      <li>
        <a
          [href]="
            'http://pinterest.com/pin/create/button/?url=https://gill-photography.com.au&media=&description=' +
            currentRoute
          "
          target="_blank"
          class="social-menu-item"
          >02. Pinterest</a
        >
      </li>
      <li>
        <a
          [href]="
            'https://twitter.com/intent/tweet?url=https://gill-photography.com.au' +
            currentRoute
          "
          target="_blank"
          class="social-menu-item"
          >03. Twitter</a
        >
      </li>
      <li>
        <a
          [href]="
            'https://www.linkedin.com/sharing/share-offsite/?url=https://gill-photography.com.au' +
            currentRoute
          "
          target="_blank"
          class="social-menu-item"
          >04. LinkedIn</a
        >
      </li>
    </ul>
  </div>
</header>

<app-sidebar
  [openSidebar]="sidebarOpen"
  (closeEvent)="receiveSidebarStatus($event)"
></app-sidebar>
