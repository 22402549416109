import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Image } from '@data/models/image.model';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-gallery-slider',
  templateUrl: './gallery-slider.component.html',
  styleUrls: ['./gallery-slider.component.scss'],
})
export class GallerySliderComponent implements OnInit {
  @Input() images: string[];
  @Input() info: any;
  @Input() scrollText: string;

  @ViewChild('swiper') swiperEl: ElementRef;

  loop = false;

  config: SwiperOptions = {
    speed: 1500,
    autoplay: {
      delay: 5000,
    },
    effect: 'fade',
    loop: this.loop,
  };

  constructor() { }

  ngOnInit() {
    this.images = this.suffleImages();
    if (this.images.length > 1) this.loop = true;
  }

  suffleImages() {
    return this.images.sort(() => Math.random() - 0.5);
  }
}
