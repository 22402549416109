<div class="slider">
  <swiper-container appSwiper [config]="config" init="false">
    <swiper-slide class="swiper-slide" *ngFor="let image of images" lazy="true">
      <div class="swiper-wrapper">
        <img [src]="image" loading="lazy" />
      </div>
    </swiper-slide>
  </swiper-container>

  <div class="slider-info">
    <h1>{{ info.title }}</h1>
    <p>{{ info.subTitle }}</p>
    <app-scroll *ngIf="scrollText" [text]="scrollText"></app-scroll>
  </div>
</div>
