import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-scroll',
  templateUrl: './scroll.component.html',
  styleUrls: ['./scroll.component.scss'],
})
export class ScrollComponent implements OnInit {
  @Input() text: string;

  constructor() {}

  ngOnInit(): void {}
}
