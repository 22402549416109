import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('@modules/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('@modules/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('@modules/blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'services',
    loadChildren: () =>
      import('@modules/services/services.module').then((m) => m.ServicesModule),
  },
  {
    path: 'minis',
    loadChildren: () =>
      import('@modules/minis/minis.module').then((m) => m.MinisModule),
  },
  {
    path: 'photos',
    loadChildren: () =>
      import('@modules/photos/photos.module').then((m) => m.PhotosModule),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
