<aside class="sidebar" [ngClass]="{ open: openSidebar === true }">
  <div class="sidebar-content">
    <!-- <section class="sidebar-section">
      <h3>Subscribe to my newsletter</h3>
      <p>
        Keep up to date with my latest projects, and be the first to know when I
        run a competition.
      </p>
      <form class="form">
        <input type="email" placeholder="Enter your email" />
        <button
          data-cursor="cursor-link"
          type="button"
          class="btn btn--black btn--full btn--lg"
        >
          Sign me up!
        </button>
      </form>
    </section> -->

    <!-- <section class="sidebar-section">
      <h3>Instagram photos</h3>
      <div id="instafeed"></div>
    </section> -->

    <section class="sidebar-section">
      <h3>Follow me on social media</h3>
      <div class="social-icons">
        <a
          data-cursor="cursor-btn"
          class="social-icon"
          href="https://www.instagram.com/gilljuergensphotography/"
          target="_blank"
        >
          <img
            data-cursor="cursor-btn"
            src="./assets/socials/instagram.svg"
            alt=""
          />
        </a>
        <a
          data-cursor="cursor-btn"
          class="social-icon"
          href="https://www.facebook.com/Gillian.Juergens.Photography/"
          target="_blank"
        >
          <img
            data-cursor="cursor-btn"
            src="./assets/socials/facebook.svg"
            alt=""
          />
        </a>
        <a
          data-cursor="cursor-btn"
          class="social-icon"
          href="#"
          target="_blank"
        >
          <img
            data-cursor="cursor-btn"
            src="./assets/socials/linkedin.svg"
            alt=""
          />
        </a>
      </div>
    </section>
  </div>
</aside>

<div
  id="sidebar-overlay"
  (click)="closeSidebar()"
  [ngClass]="{
    'sidebar-overlay': openSidebar === true,
    'sidebar-overlay-dark': openSidebar === true
  }"
  data-cursor="cursor-close"
></div>
