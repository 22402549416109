import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import Instafeed from 'instafeed.js';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() openSidebar = false;
  @Output() closeEvent = new EventEmitter<boolean>();

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    // this.getInstagramToken();
  }

  getInstagramToken() {
    const url =
      'https://ig.instant-tokens.com/users/5edbf3e3-40b7-4b8d-8e5e-8e5147a2dbd6/instagram/17841403170144004/token?userSecret=m9gn9w7nyfj0ob58i5lthyf';

    this.http.get(url).subscribe((data: any) => {
      this.loadInstagramImages(data.Token);
    });
  }

  loadInstagramImages(token: string) {
    const feed = new Instafeed({
      accessToken: token,
    });
    feed.run();
  }

  closeSidebar() {
    this.closeEvent.emit(true);
  }
}
