<section [id]="section.id">
  <div fxLayout="column" fxLayoutAlign="center start" class="content-block">
    <div class="title-section">
      <h2>{{ section.title }}</h2>
      <h3 *ngIf="section.subTitle">{{ section.subTitle }}</h3>
    </div>
    <div class="copy" [innerHTML]="html" routeTransformer></div>
    <p *ngIf="section.button">
      <a
        class="btn btn--black btn--lg"
        [routerLink]="section.button.link"
        [queryParams]="section.button.params"
        data-cursor="cursor-link"
        >{{ section.button.text }}</a
      >
    </p>
  </div>
</section>
