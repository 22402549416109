import {
  Component,
  ElementRef,
  HostListener,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs/operators';
import { register } from 'swiper/element/bundle';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild('cursor') cursor: ElementRef;

  offsets = {
    default: 15,
    'cursor-link': 10,
    'cursor-btn': 25,
    'cursor-close': 25,
    'cursor-scroll': 25,
  };
  offset = this.offsets.default;

  constructor(private renderer: Renderer2, private router: Router) {
    register();

    const navEndEvents$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
    navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-4XSLZVR7CR', {
        page_path: event.urlAfterRedirects,
      });
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: any) {
    this.cursor.nativeElement.setAttribute(
      'style',
      'display: block; top: ' +
        (event.pageY - this.offset) +
        'px; left: ' +
        (event.pageX - this.offset) +
        'px;'
    );
  }

  @HostListener('mouseover', ['$event.target'])
  onMouseOver(element: HTMLElement) {
    const dataAttr = element.getAttribute('data-cursor');

    if (dataAttr) {
      this.offset = this.offsets[dataAttr];
      this.renderer.addClass(this.cursor.nativeElement, dataAttr);
    }
  }

  @HostListener('mouseout', ['$event.target'])
  onMouseOut(element: HTMLElement) {
    const dataAttr = element.getAttribute('data-cursor');

    if (dataAttr) {
      this.offset = this.offsets.default;
      this.renderer.removeClass(this.cursor.nativeElement, dataAttr);
    }
  }
}
