import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss', './hamburger.scss', './dots.scss'],
})
export class NavigationComponent implements OnInit {
  // Navigation Hamburger
  isActive = false;

  // Sidebar and dot animation
  dotsActive = false;
  sidebarOpen = false;

  // Social menu
  socialsOpen = false;
  currentRoute: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.currentRoute = window.location.pathname;
  }

  onClick(target: string) {
    this.isActive = false;
    this.dotsActive = false;
    this.sidebarOpen = false;
    this.socialsOpen = false;

    this.router.navigate([`/${target}`]);
  }

  toggleNav() {
    this.isActive = !this.isActive;
  }

  toggleSidebar() {
    // Open sidebar
    if (this.socialsOpen) {
      // Close
      setTimeout(() => {
        this.sidebarOpen = !this.sidebarOpen;
      }, 300);
      this.socialsOpen = !this.socialsOpen;
    } else {
      this.sidebarOpen = !this.sidebarOpen;
    }

    // Animate dots
    this.dotsActive = !this.dotsActive;
  }

  toggleSocialMenu() {
    if (this.sidebarOpen) {
      setTimeout(() => {
        this.socialsOpen = !this.socialsOpen;
      }, 300);
      this.dotsActive = false;
      this.sidebarOpen = !this.sidebarOpen;
    } else {
      this.socialsOpen = !this.socialsOpen;
    }
  }

  receiveSidebarStatus($event) {
    this.dotsActive = false;
    this.sidebarOpen = false;
  }
}
