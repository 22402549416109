import { Component, OnInit, Input } from '@angular/core';
import { Section } from '@data/models/page.model';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss'],
})
export class BottomNavComponent implements OnInit {
  @Input() navItems: Section[];
  currentRoute: string;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.currentRoute = this.router.url;
  }
}
