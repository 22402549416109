import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ScrollComponent } from './components/scroll/scroll.component';
import { InitialsPipe } from './pipes/initials.pipe';
import { GallerySliderComponent } from './components/gallery-slider/gallery-slider.component';
import { BottomNavComponent } from './components/bottom-nav/bottom-nav.component';
import { ContentComponent } from './components/content/content.component';
import { MousePositionDirective } from './directives/mouse-position.directive';
import { RouteTransformerDirective } from './directives/route-transformer.directive';
import { LoaderComponent } from './components/loader/loader.component';
import { SwiperDirective } from './directives/swiper.directive';

const sharedModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  FlexLayoutModule,
];

const sharedComponents = [
  ScrollComponent,
  GallerySliderComponent,
  BottomNavComponent,
  ContentComponent,
  LoaderComponent,
];

const sharedDirectives = [
  MousePositionDirective,
  RouteTransformerDirective,
  SwiperDirective,
];

const sharedPipes = [InitialsPipe];

@NgModule({
  declarations: [...sharedComponents, ...sharedPipes, ...sharedDirectives],
  imports: sharedModules,
  exports: [
    ...sharedModules,
    ...sharedComponents,
    ...sharedDirectives,
    ...sharedPipes,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
